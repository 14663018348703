.table-row {
  display: flex;
  position: relative;
  /* border-bottom: 1px solid #e0e0e0; */
  box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
  margin-bottom: 10px;
  transition: 0.3s;
}
.table-row:hover {
  box-shadow: 0px 2px 10px rgb(0 0 0 / 40%);
  /* box-shadow: 0px 0px 10px rgb(0 0 0 / 40%); */
  transform: translate3d(0, -2px, 0);
}

.table-row .table-row__item {
  margin: 20px 5px;
  display: flex;
  font-size: 0.8rem;
  justify-content: left;
  align-items: center;
  overflow: hidden;
  padding: 0 7px;
  font-family: 'Roboto';
}

.table-row .table-row__item:last-child {
  justify-content: center;
}

@media (max-width: 600px) {
  .table-row {
    display: flex;
    flex-wrap: wrap;
    padding: 40px 0 10px 0;
  }
  .table-row .table-row__item {
    width: 45% !important;
    margin: 5px 0;
    overflow: hidden;
    border: hidden;
    /* display: -webkit-box !important; */
    /* -webkit-box-orient: vertical; */
    /* -webkit-line-clamp: 3; */
    /* white-space: pre-wrap; */
  }

  .table-row .table-row__item:nth-child(1) {
    font-size: 1.4rem !important;
    font-weight: 600 !important;
  }

  .row-options {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
  }
}
